import * as React from 'react';
import styled from 'styled-components';

import CalculatorMobile from '../images/calculator_mobile.svg';
import Calculator from '../images/calculator.svg';

import Button from '../components/Basic/Button';
import Layout from '../components/Layout/Layout';
import Container from '../components/Basic/Container';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Seo from '../components/seo';

const Hero = styled.section`
  width: 100%;
  display: flex;
  margin-top: -130px;
  background: ${({ theme }) => theme.backgroundColor.primary};
  background-image: ${({ theme }) => `url(${CalculatorMobile}), linear-gradient(132deg, ${theme.gradient.first} 0%, ${theme.gradient.second} 100%)`};
  background-repeat: no-repeat;
  background-size: 140%, 100%;
  background-position: 50% 10%;
  align-items: center;
  justify-content: space-between;
  padding: 170px 0px 50px 0px;
  flex-direction: row;
  border-radius: 0 0 100px 0;

  @media ${props => props.theme.media.fablet} {
    flex-direction: column;
    padding: 170px 0px 30px 0px;
    background-image: ${({ theme }) => `url(${Calculator}), linear-gradient(132deg, ${theme.gradient.first} 0%, ${theme.gradient.second} 100%)`};
    background-size: 75%,100%;
    background-position: 100% 46%;
  }
  @media ${props => props.theme.media.desktop} {
    margin-top: -150px;
  }
`;

const HeroInner = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;

  @media ${props => props.theme.media.desktop} {
    padding: 40px;
  }
`;

const HeroPart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1{
    color: ${({ theme }) => theme.neutralColor.white};
    text-align: center;
    padding: 0px 30px;
    font-size: 2.5rem;
    font-weight: 600;
  }

  @media ${props => props.theme.media.desktop} {
    width: 30%;
    align-items: start;

    h1{
      text-align: left;
      padding: 0px 0px;
    }
  }
`;

const StyledCTA = styled.div`
  display: flex;
  padding: 20px 0px;
  justify-content: center;

  @media ${props => props.theme.media.desktop} {
    padding: 0;
  }
`;


const NotFoundPage = () => (
  <Layout>
    <Seo title="biurobmjg.pl - Podana strona nie istnieje" />
    <Hero>
      <Container>
        <HeroInner>
          <HeroPart>
            <h1> 404: Podana Strona Nie Istnieje </h1>
            <StyledCTA>
              <AnchorLink to="/" >
                <Button color="secondary" round="5px" margin="30px 0px" name="Kontakt">
                  Strona Główna
                </Button>
              </AnchorLink>
            </StyledCTA>
          </HeroPart>
        </HeroInner>
      </Container>
    </Hero>
    <div style={{ height: '100px' }}>

    </div>
  </Layout>
);

export default NotFoundPage;
